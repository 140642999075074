import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!
  const _component_Basic = _resolveComponent("Basic")!
  const _component_LightStyle = _resolveComponent("LightStyle")!
  const _component_SquareBadge = _resolveComponent("SquareBadge")!
  const _component_CircleBadge = _resolveComponent("CircleBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_Basic),
      _createVNode(_component_LightStyle),
      _createVNode(_component_SquareBadge),
      _createVNode(_component_CircleBadge)
    ])
  ]))
}